import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Java Chat Instructions",
  "path": "/javachatinstructions.html"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p className="text-center">
  Ummm... ok. Hi!!! I'm Gokou. Got that down? All right. First thing is first.
    </p>
    <p><strong parentName="p">{`INSTRUCTIONS:`}</strong></p>
    <p>{`First thing is a box will appear. Type a name you will be known as. The loading may take awhile. So be patient.`}</p>
    <p><strong parentName="p">{`RULES:`}</strong></p>
    <p>{`Please don't trash talk to the people who have an @ before their name. They are more superior. And no flooding. flooding is the use of repeating the dame thing over atleast 5 times.`}</p>
    <p><strong parentName="p">{`WAYS TO GET AROUND:`}</strong></p>
    <p>{`Okay... here's away to change your name. In the small little box put: /nick `}{`*`}{`put your name here`}{`*`}{`. Oh... yeah. No periods or stars that goes for the others ways to get around. If you wanna show a action your doing type this: EXAMPLE: /me is eating. It will appear like this: `}{`*`}{` `}{`*`}{`Name`}{`*`}{` is eating.`}</p>
    <p>{`Well you got that. If your really good I might make you a +. It's okay... Well your ready.`}</p>
    <div className="text-center">
  <p>Click the link below.</p>
  <a href="javachat.html">I'm Ready to Chat!</a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      